import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"

import { Content } from "page_components/office"

const Apartments = ({ data, location }) => {
  const title = "Offices and premises"

  return (
    <Layout
      location={location}
      seo={{
        title: "Offices and premises",
      }}
      rmGlobalPopup
    >
      <Breadcrumbs title={title} />
      <Content
        allWpOfficeSkyTrust={data?.allWpOfficeSkyTrust?.nodes}
        allWpOfficeSkyTrust2={data?.allWpOfficeSkyTrust2?.nodes}
        allWpOfficeKrakowskaRogatka={data?.allWpOfficeKrakowskaRogatka?.nodes}
        allWpOfficeRezydencjaCentrum={data?.allWpOfficeRezydencjaCentrum?.nodes}
        allWpOfficeRezydencjaSwierkowa={
          data?.allWpOfficeRezydencjaSwierkowa?.nodes
        }
        allWpOfficeHugoScobel={data?.allWpOfficeHugoScobel?.nodes}
      />
      <ContactForm formType="sale" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpOfficeSkyTrust(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfOffice {
          apartmentNumber
          investment
          status
          area
          floor
          visualization2d {
            sourceUrl
          }
          visualization3durl
          floorView {
            sourceUrl
          }
          pdf {
            sourceUrl
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allWpOfficeSkyTrust2(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfOffice {
          apartmentNumber
          investment
          status
          area
          floor
          visualization2d {
            sourceUrl
          }
          visualization3durl
          floorView {
            sourceUrl
          }
          pdf {
            sourceUrl
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allWpOfficeKrakowskaRogatka(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfOffice {
          apartmentNumber
          investment
          status
          area
          floor
          visualization2d {
            sourceUrl
          }
          visualization3durl
          floorView {
            sourceUrl
          }
          pdf {
            sourceUrl
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allWpOfficeRezydencjaCentrum(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfOffice {
          apartmentNumber
          investment
          status
          area
          floor
          visualization2d {
            sourceUrl
          }
          visualization3durl
          floorView {
            sourceUrl
          }
          pdf {
            sourceUrl
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allWpOfficeRezydencjaSwierkowa(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfOffice {
          apartmentNumber
          investment
          status
          area
          floor
          visualization2d {
            sourceUrl
          }
          visualization3durl
          floorView {
            sourceUrl
          }
          pdf {
            sourceUrl
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allWpOfficeHugoScobel(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfOffice {
          apartmentNumber
          investment
          status
          area
          floor
          visualization2d {
            sourceUrl
          }
          visualization3durl
          floorView {
            sourceUrl
          }
          pdf {
            sourceUrl
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Apartments
